export const APP_TYPES = [
  "backend_action",
  "menu",
  "game",
  "recording",
  "live",
  "serial_recording",
  "prompt",
];

export const LOOP_SUBSCRIPTION_PLAN_IDS = [10, 19, 20];
