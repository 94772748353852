import { Grid, Typography, Box, Button } from "@mui/material";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { yup } from "../../lib";
import { adminActions } from "../../state";
import { SubscriptionPlan } from "../../state/types";
import { InputField } from "../inputs/InputField";
interface Props {
  closeModal: () => void;
  subscriptionPlan: SubscriptionPlan;
}
export const SubscriptionPlanForm = ({
  closeModal,
  subscriptionPlan,
}: Props) => {
  const validationSchema = yup.object({
    max_child_count: yup
      .number()
      .typeError("Must be a number")
      .required("max child count is required"),
    max_phone_number_count: yup
      .number()
      .typeError("Must be a number")
      .required("Max phone number count is required"),
    weekly_point_limit: yup
      .number()
      .typeError("Must be a number")
      .required("Weekly point limit is required"),
  });

  const dispatch = useDispatch();
  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(adminActions.updateSubscriptionPlan(subscriptionPlan.id, values));
    setSubmitting(false);
    closeModal();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={subscriptionPlan}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid
            sx={{
              width: "100%",
              border: "1px solid #E4E7EB",
              borderRadius: 2,
              m: 1,
              mt: 2,
              p: 2,
            }}
            display={"flex"}
            flexDirection="column"
            container
          >
            <Typography variant="h6">Edit Subscription</Typography>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <InputField label={"name"} name={"name"} disabled />
            </Grid>
            <Grid item xs={12}>
              <InputField label={"Max Child Count"} name={"max_child_count"} />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label={"Max Phone Number Count"}
                name={"max_phone_number_count"}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label={"Weekly Point Limit"}
                name={"weekly_point_limit"}
              />
            </Grid>
          </Grid>
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <Button
              sx={{ mt: 3, ml: 3, mr: 0 }}
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save Subscription Plan
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
