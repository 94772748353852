import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deepCopy } from "../../lib/utils";
import {
  List,
  User,
  Menu,
  Customer,
  Recording,
  AppType,
  RecordingType,
  Child,
  Serials,
  LiveShows,
  CustomerPhones,
  Voicemails,
  SerialRecording,
  CustomerNotes,
  GameRule,
  Game,
  MenuStats,
  Alias,
  ChildrenPoints,
  Reports,
  VoicemailBox,
  GameVersion,
  Voicemail,
  Series,
  Raffle,
  SubscriptionPlan,
} from "../types";
export interface AdminState {
  users: List<User>;
  menus: List<Menu>;
  customers: List<Customer>;
  customerPhone: CustomerPhones;
  customer: Customer;
  customerVoicemails: Voicemail[];
  customerNotes: List<CustomerNotes>;
  children: List<Child>;
  childInfo: Child;
  games: List<Game>;
  gameRules: List<GameRule>;
  recordings: List<Recording>;
  serials: List<Serials>;
  serial: Serials;
  serialRecordings: List<SerialRecording>;
  liveShows: List<LiveShows>;
  voicemails: List<Voicemails>;
  voicemailBoxes: List<VoicemailBox>;
  voicemailBox: VoicemailBox;
  app_types: List<AppType>;
  recording_types: List<RecordingType>;
  aliases: List<Alias>;
  points: List<ChildrenPoints>;
  reports: List<Reports>;
  series: List<Series>;
  alias: Alias;
  raffle: Raffle;
  stats: {
    activeCustomers: number;
    activeChildren: number;
    filterStart?: Date;
    filterEnd?: Date;
    total_calls_sub: number | "?";
    total_calls_non_sub: number | "?";
    unique_caller_id_sub: number | "?";
    unique_caller_id_non_sub: number | "?";
    avg_duration_sub: number | "?";
    avg_duration_non_sub: number | "?";
  };
  menuStats: MenuStats[];
  gameVersions: GameVersion[];
  raffles: List<Raffle>;
  subscriptionPlans: SubscriptionPlan[];
}
const defaultChildInfo = {
  id: 0,
  customer_id: 0,
  first_name: "",
  last_name: "",
  dob: "",
  gender: "",
  recording_id: 0,
  active: false,
  pin: "",
  children_points: [],
  customer: {
    address: "",
    address_2: "string",
    city: "",
    state: "",
    zip: "",
    customer_phones: [],
  },
};
const defaultCustomerPhone = {
  id: 0,
  customer_id: 0,
  label: "",
  country: "",
  country_code: "",
  area_code: "",
  phone_number: "",
};
const defaultCustomer = {
  id: 0,
  user_id: 0,
  first_name: "",
  last_name: "",
  address: "",
  address_2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  notes: "",
  uuid: "",
  active: true,
  pin: "",
  user: { email: "", first_name: "", last_name: "", phone: "" },
  customer_children: [],
  customer_phones: [],
};
const defaultAlias = {
  id: 0,
  original_name: "",
  alias: "",
  created_at: new Date(),
};
const defaultSerial = {
  id: 0,
  name: "",
  has_votes: 0,
  active: false,
  app_id: 0,
};
const defaultRaffle = {
  id: 0,
  name: "",
  ticket_price: 0,
  auto_entry: false,
  start_date: "",
  end_date: "",
  weekly: false,
  recording_id: null,
  created_at: new Date(),
};

const initialState: AdminState = {
  users: {
    count: 0,
    rows: [],
  },
  menus: {
    count: 0,
    rows: [],
  },
  liveShows: {
    count: 0,
    rows: [],
  },
  voicemails: {
    count: 0,
    rows: [],
  },
  voicemailBoxes: {
    count: 0,
    rows: [],
  },
  voicemailBox: {
    id: 0,
    app_id: 0,
    name: "",
    recording_id: null,
    external_name: null,
  },
  customers: {
    count: 0,
    rows: [],
  },
  customer: defaultCustomer,
  customerVoicemails: [],
  customerNotes: {
    count: 0,
    rows: [],
  },
  customerPhone: defaultCustomerPhone,
  children: {
    count: 0,
    rows: [],
  },
  childInfo: defaultChildInfo,
  recordings: {
    count: 0,
    rows: [],
  },
  serials: {
    count: 0,
    rows: [],
  },
  serialRecordings: {
    count: 0,
    rows: [],
  },
  app_types: {
    count: 0,
    rows: [],
  },
  recording_types: {
    count: 0,
    rows: [],
  },
  games: {
    count: 0,
    rows: [],
  },
  gameRules: {
    count: 0,
    rows: [],
  },
  aliases: {
    count: 0,
    rows: [],
  },
  points: {
    count: 0,
    rows: [],
  },
  reports: {
    count: 0,
    rows: [],
  },
  series: {
    count: 0,
    rows: [],
  },
  raffles: {
    count: 0,
    rows: [],
  },
  raffle: defaultRaffle,
  alias: defaultAlias,
  stats: {
    activeCustomers: 0,
    activeChildren: 0,
    filterStart: undefined,
    filterEnd: undefined,
    total_calls_sub: "?",
    total_calls_non_sub: "?",
    unique_caller_id_sub: "?",
    unique_caller_id_non_sub: "?",
    avg_duration_sub: "?",
    avg_duration_non_sub: "?",
  },
  menuStats: [],
  gameVersions: [],
  serial: defaultSerial,
  subscriptionPlans: [],
};
export const admin = {
  ...createSlice({
    name: "admin",
    initialState,
    reducers: {
      setUsers(state, { payload }: PayloadAction<List<User>>) {
        state.users = payload;
      },
      setMenus(state, { payload }: PayloadAction<List<Menu>>) {
        state.menus = payload;
      },
      setLiveShows(state, { payload }: PayloadAction<List<LiveShows>>) {
        state.liveShows = payload;
      },
      setVoiceMails(state, { payload }: PayloadAction<List<Voicemails>>) {
        state.voicemails = payload;
      },
      setVoiceMailBoxes(state, { payload }: PayloadAction<List<VoicemailBox>>) {
        state.voicemailBoxes = payload;
      },
      setVoicemailBox(state, { payload }: PayloadAction<VoicemailBox>) {
        state.voicemailBox = payload;
      },
      setCustomers(state, { payload }: PayloadAction<List<Customer>>) {
        state.customers = payload;
      },
      setCustomer(state, { payload }: PayloadAction<Customer>) {
        state.customer = payload;
      },
      mergeIntoCustomer(state, { payload }: PayloadAction<Partial<Customer>>) {
        const customer = deepCopy(state.customer);
        const final = { ...customer, ...payload };
        state.customer = final;
      },
      setCustomerVoicemails(state, { payload }: PayloadAction<Voicemail[]>) {
        state.customerVoicemails = payload;
      },
      setCustomerNotes(state, { payload }: PayloadAction<List<CustomerNotes>>) {
        state.customerNotes = payload;
      },
      setCustomerPhone(state, { payload }: PayloadAction<CustomerPhones>) {
        state.customerPhone = payload;
      },
      setDefaultCustomerPhone(state) {
        state.customerPhone = defaultCustomerPhone;
      },
      setChildren(state, { payload }: PayloadAction<List<Child>>) {
        state.children = payload;
      },
      setChildInfo(state, { payload }: PayloadAction<Child>) {
        state.childInfo = payload;
      },
      setDefaultChildInfo(state) {
        state.childInfo = defaultChildInfo;
      },
      setRecordings(state, { payload }: PayloadAction<List<Recording>>) {
        state.recordings = payload;
      },
      setGames(state, { payload }: PayloadAction<List<Game>>) {
        state.games = payload;
      },
      setGameRules(state, { payload }: PayloadAction<List<GameRule>>) {
        state.gameRules = payload;
      },
      setGameVersions(state, { payload }: PayloadAction<GameVersion[]>) {
        state.gameVersions = payload;
      },
      setSerials(state, { payload }: PayloadAction<List<Serials>>) {
        state.serials = payload;
      },
      setSerial(state, { payload }: PayloadAction<Serials>) {
        state.serial = payload;
      },
      setDefaultSerial(state) {
        state.serial = defaultSerial;
      },
      setReports(state, { payload }: PayloadAction<List<Reports>>) {
        state.reports = payload;
      },
      setSerialRecordings(
        state,
        { payload }: PayloadAction<List<SerialRecording>>,
      ) {
        state.serialRecordings = payload;
      },
      setRecordingTypes(
        state,
        { payload }: PayloadAction<List<RecordingType>>,
      ) {
        state.recording_types = payload;
      },
      setAppTypes(state, { payload }: PayloadAction<List<AppType>>) {
        state.app_types = payload;
      },
      setAliases(state, { payload }: PayloadAction<List<Alias>>) {
        state.aliases = payload;
      },
      setAlias(state, { payload }: PayloadAction<Alias>) {
        state.alias = payload;
      },
      setPoints(state, { payload }: PayloadAction<List<ChildrenPoints>>) {
        state.points = payload;
      },
      setSeries(state, { payload }: PayloadAction<List<Series>>) {
        state.series = payload;
      },
      setRaffles(state, { payload }: PayloadAction<List<Raffle>>) {
        state.raffles = payload;
      },
      setRaffle(state, { payload }: PayloadAction<Raffle>) {
        state.raffle = payload;
      },
      setDefaultRaffle(state) {
        state.raffle = defaultRaffle;
      },
      setDefaultAlias(state) {
        state.alias = defaultAlias;
      },
      setDefaultVMBox(state) {
        state.voicemailBox = {
          id: 0,
          app_id: 0,
          name: "",
          external_name: "",
        };
      },
      setStats(state, { payload }: PayloadAction<Record<string, any>>) {
        state.stats = {
          ...state.stats,
          ...payload,
        };
      },
      setMenuStats(state, { payload }: PayloadAction<MenuStats[]>) {
        state.menuStats = payload;
      },
      setSubscriptionPlans(
        state,
        { payload }: PayloadAction<SubscriptionPlan[]>,
      ) {
        state.subscriptionPlans = payload;
      },
    },
  }),
  persist: false,
};
