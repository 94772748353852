import { Typography } from "@mui/material";
import { RootListViewStyled } from "./GenericPage.styles";
import { SUBSCRIPTION_PLANS_COLUMNS } from "../../components/tables/columns";
import { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { ToolBar } from "../../components";
import { SubscriptionPlanForm } from "../../components/forms/SubscriptionPlanForm";
import CustomModal from "../../components/modal/CustomModal";

export const SubscriptionsPlansPage = () => {
  const [pageSize, setPageSize] = useState(25);
  const [openModal, setOpenModal] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState({
    id: 0,
    max_child_count: 0,
    max_phone_number_count: 0,
    weekly_point_limit: 0,
    plan_type: "",
  });

  const dispatch = useDispatch();
  const subscriptions = useSelector(adminSelectors.subscriptionPlans).filter(
    (p) => p.plan_type === "loop",
  );
  const openModalClick = () => {
    setOpenModal(!openModal);
    dispatch(adminActions.setDefaultAlias());
  };
  const openModalEdit = async (subscriptionPlan) => {
    setOpenModal(!openModal);
    setSubscriptionPlan(subscriptionPlan);
  };
  useEffect(() => {
    dispatch(adminActions.getSubscriptionPlans());
  }, [dispatch]);

  return (
    <RootListViewStyled>
      <Typography variant="h3" mb={3}>
        Loop Subscription Plans
      </Typography>

      <DataGridPro
        rows={subscriptions}
        columns={SUBSCRIPTION_PLANS_COLUMNS}
        pagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onRowClick={(v) => openModalEdit(v.row)}
        components={{
          Toolbar: ToolBar,
        }}
      />
      <CustomModal open={openModal} onClose={openModalClick}>
        <SubscriptionPlanForm
          closeModal={openModalClick}
          subscriptionPlan={subscriptionPlan}
        />
      </CustomModal>
    </RootListViewStyled>
  );
};
