import {
  Box,
  Grid,
  IconButton,
  Typography,
  styled,
  useTheme,
  Autocomplete,
  TextField,
} from "@mui/material";
import { TabPanel } from "./TabPanel";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
//import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useParams, useLocation } from "react-router-dom";
import { CountCard } from "../CountCard";
import {
  adminActions,
  adminSelectors,
  customerActions,
  customerSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import CustomModal from "../../components/modal/CustomModal";
import { useEffect, useState } from "react";
import { AddButton } from "../buttons/AddButton";
import { ChildForm } from "../forms/ChildForm";
import { AddPhoneForm } from "../forms/AddPhoneForm";
import { NoteForm } from "../forms/NoteForm";
import { useMobile } from "../../themes";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { CHILDREN_COLUMNS_GENERAL, PHONE_COLUMNS } from "../tables/columns";
import { Footer } from "../tables/Footer";
import { formatPhone } from "../../lib";
import sirConferenceChair from "../../assets/img/sir_conference_chair.png";
import { LOOP_SUBSCRIPTION_PLAN_IDS } from "../../state/constants";

interface GeneralProps {
  value: string;
  mode: "customer" | "admin";
}

const DataGrid = styled(DataGridPro)(() => ({
  "& .MuiDataGrid-columnHeaders": { display: "none" },
  "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },
  "& .MuiDataGrid-main": {
    borderRight: "none",
    borderLeft: "none",
  },
  "& .MuiDataGrid-footerContainer": {
    display: "none",
  },
}));

export const CustomerGeneralTab = ({ value, mode }: GeneralProps) => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const [noteId, setNoteId] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [addPhoneModal, setAddPhoneModal] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const customerNotes = useSelector(adminSelectors.customerNotes);
  const loopSubscriptions = useSelector(customerSelectors.loopSubscriptions);
  const customer =
    mode === "admin"
      ? useSelector(adminSelectors.customer)
      : useSelector(customerSelectors.customer).customer;

  const customerSubscriptions = useSelector(
    adminSelectors.customerSubscriptions,
  );

  const { id, subscription_id } = useParams();
  const location = useLocation();

  const [subscription_filter, setSubscriptionFilter] = useState<
    string | number
  >("");

  const dispatch = useDispatch();
  const addChildClick = () => {
    setOpenModal(!openModal);
    dispatch(adminActions.setDefaultChildInfo());
  };
  const addPhoneClick = () => {
    setAddPhoneModal(!addPhoneModal);
    dispatch(adminActions.setDefaultCustomerPhone());
  };
  const addNoteClick = () => {
    setAddNoteModal(!addNoteModal);
    setNoteId(0);
  };

  const activeSubscription = loopSubscriptions.find(
    (s) => s.id?.toString() === subscription_id,
  );

  const customerChildren = subscription_id
    ? activeSubscription?.subscription_children || []
    : customer.customer_children ?? [];

  const customer_phones = subscription_id
    ? activeSubscription?.subscription_phones ?? []
    : customer.customer_phones?.filter(
        (p) =>
          subscription_filter === "" ||
          p.subscription_id === subscription_filter, //In customer mode only show loop numbers
      ) ?? [];

  const max_child_count =
    activeSubscription?.subscription_plan?.max_child_count ?? 4;
  const max_phone_number_count =
    activeSubscription?.subscription_plan?.max_phone_number_count ?? 2;
  const deleteNote = (noteId) => {
    dispatch(adminActions.deleteNote(noteId));
  };
  const editNote = async (id) => {
    setNoteId(id);
    setAddNoteModal(!addNoteModal);
  };
  useEffect(() => {
    if (mode === "customer") {
      dispatch(customerActions.getAllInfoOnLogin());
    } else {
      if (location.pathname.includes("general")) {
        dispatch(adminActions.getCustomer(id));
        dispatch(adminActions.getCustomerNotes(Number(id)));
      }
    }
  }, [dispatch, customer.id]);
  const TableGrid = mode === "customer" ? DataGrid : DataGridPro;

  return (
    <TabPanel
      value={value}
      index={0}
      sx={{
        width:
          mode === "admin" && location.pathname.includes("general")
            ? "initial"
            : "100%",
        mt: isMobile ? 0 : 2,
      }}
    >
      <Grid
        container
        display={" flex"}
        sx={{ width: "100%", overflowX: "hidden" }}
      >
        <Grid
          item
          xs={12}
          md={
            mode === "admin" &&
            location.pathname.includes("general") &&
            !isMobile
              ? 8
              : 12
          }
          width={
            mode === "admin" && location.pathname.includes("general")
              ? "70%"
              : "100%"
          }
          borderRight={mode === "admin" ? "1px solid #E4E7EB" : ""}
        >
          {mode === "admin" && (
            <>
              <Box
                sx={{ pl: 0, pt: 0, mb: 2, fontWeight: "bold", width: "50%" }}
              >
                <Autocomplete
                  id="selectSubscription"
                  onChange={(e, newValue) => {
                    setSubscriptionFilter(newValue?.id || "");
                  }}
                  options={
                    customerSubscriptions
                      ?.filter(
                        (s) =>
                          !!s.active &&
                          LOOP_SUBSCRIPTION_PLAN_IDS.includes(
                            s.subscription_plan_id ?? 0,
                          ),
                      )
                      .map((s, idx) => {
                        return {
                          label:
                            idx +
                            1 +
                            " - " +
                            ((s?.phone?.phone_number?.length === 10
                              ? formatPhone(s.phone.phone_number)
                              : formatPhone(
                                  s?.phone?.area_code ??
                                    "" + s?.phone?.phone_number,
                                )) ?? "") +
                            " - " +
                            s.shipping_name +
                            " | " +
                            s.provider_subscription_id,
                          id: s.id!,
                        };
                      }) ?? []
                  }
                  getOptionLabel={(option: any) => option?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Subscription"
                    />
                  )}
                />
              </Box>
            </>
          )}
          {/*  */}
          <Box sx={{ pl: 0, pt: 0, mb: 2, fontWeight: "bold" }}>
            {mode === "admin" ? (
              <Typography variant="h3"> Phone numbers</Typography>
            ) : (
              <>
                <Typography variant="h3">
                  Phone numbers
                  <Box
                    component={"span"}
                    fontSize={16}
                    color={palette.text.disabled}
                  >{` ${customer_phones.length}/${max_phone_number_count}`}</Box>
                </Typography>
                <Typography variant="body1">
                  Choose the phone numbers that The Loop can be called from
                </Typography>
              </>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: 4,
              mb: isMobile ? 8 : 1,
            }}
          >
            <TableGrid
              autoHeight
              columns={PHONE_COLUMNS}
              rows={customer_phones}
              getRowId={(row) => row.id || row.phone_number}
              components={{
                Footer: Footer,
              }}
              // componentsProps={{
              //   footer: {
              //     onClick: addPhoneClick,
              //     mode: "customer",
              //     show:
              //       mode === "admin" ||
              //       (mode === "customer" &&
              //         customer.customer_phones &&
              //         customer.customer_phones.length < 2),
              //   },
              // }}
              disableColumnResize
              disableColumnSelector
              hideFooterPagination
              hideFooterSelectedRowCount
              hideFooterRowCount
              columnVisibilityModel={{
                number_type: mode === "admin",
              }}
              sx={{
                ".MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                ".MuiDataGrid-main": {
                  borderBottom: "none",
                },
              }}
            />
            {mode === "customer" && !isMobile && (
              <img
                style={{ position: "relative", top: -70 }}
                src={sirConferenceChair}
                alt="sir_conference_chair"
              />
            )}
          </Box>
          {(customer_phones.length < max_phone_number_count ||
            mode === "admin") && (
            <Box sx={{ mb: 8, width: 180 }}>
              <AddButton label={"Add"} onClick={addPhoneClick} />
            </Box>
          )}

          <Box sx={{ pl: 0, pt: 0, mb: 2, fontWeight: "bold" }}>
            {mode === "admin" ? (
              <Typography variant="h3"> Children</Typography>
            ) : (
              <>
                <Typography variant="h3">
                  {" "}
                  Children{" "}
                  <Box
                    component={"span"}
                    fontSize={16}
                    color={palette.text.disabled}
                  >{` ${customerChildren.length}/${max_child_count}`}</Box>
                </Typography>
                <Typography variant="body1">
                  Choose which children will get a personal account to The Loop
                </Typography>
              </>
            )}
          </Box>
          <TableGrid
            autoHeight
            columns={CHILDREN_COLUMNS_GENERAL}
            rows={
              customerChildren
                ? customerChildren.filter(
                    (c) =>
                      subscription_filter === "" ||
                      c.subscription_id === subscription_filter,
                  )
                : []
            }
            components={{
              Footer: Footer,
            }}
            componentsProps={{
              footer: {
                onClick: addChildClick,
                mode,
                show:
                  mode === "admin" ||
                  (mode === "customer" &&
                    customerChildren &&
                    customerChildren?.length < max_child_count),
              },
            }}
            hideFooterPagination
            hideFooterSelectedRowCount
            disableColumnResize
            disableColumnSelector
            sx={{
              mb: 4,
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              ".MuiDataGrid-main": {
                borderBottom: "none",
              },
            }}
            hideFooterRowCount
            columnVisibilityModel={{
              points_earned: mode === "admin",
              points_used: mode === "admin",
              points_bal: mode === "admin",
            }}
          />
          <CustomModal open={openModal} onClose={addChildClick}>
            <ChildForm
              mode={mode}
              subscription_filter={
                subscription_filter != ""
                  ? subscription_filter
                  : customerSubscriptions.find(
                      (s) =>
                        !!s.active &&
                        LOOP_SUBSCRIPTION_PLAN_IDS.includes(
                          s.subscription_plan_id ?? 0,
                        ) &&
                        customerSubscriptions.length === 1,
                    )?.id
              }
              isModal
              closeModal={addChildClick}
            />
          </CustomModal>

          <CustomModal open={addPhoneModal} onClose={addPhoneClick}>
            <AddPhoneForm
              closeModal={addPhoneClick}
              subscription_filter={
                subscription_filter != ""
                  ? subscription_filter
                  : customerSubscriptions.find(
                      (s) =>
                        !!s.active &&
                        LOOP_SUBSCRIPTION_PLAN_IDS.includes(
                          s.subscription_plan_id ?? 0,
                        ) &&
                        customerSubscriptions.length === 1,
                    )?.id
              }
              mode={mode}
            />
          </CustomModal>
        </Grid>
        {mode === "admin" && location.pathname.includes("general") && (
          <Grid item xs={12} md={3}>
            <Box m={4}>
              <Typography fontWeight={"bold"}>Notes</Typography>
              {customerNotes &&
                customerNotes.map((n) => {
                  return (
                    <CountCard
                      sx={{ height: "fit-content", width: "100%", p: 2, pb: 0 }}
                      key={n.id}
                    >
                      <Typography fontSize={12}>{n.note}</Typography>
                      <Box
                        mt={2}
                        width={"100%"}
                        display={"inline-flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography fontSize={11}>
                          {`${n.user?.first_name} ${n.user?.last_name}`}
                        </Typography>
                        <Typography fontSize={11}>
                          {new Date(
                            n.updated_at ? n.updated_at : n.created_at,
                          ).toLocaleDateString()}
                        </Typography>
                        <Box>
                          <IconButton onClick={() => editNote(n.id)}>
                            <ModeEditIcon fontSize={"small"} />
                          </IconButton>
                          <IconButton onClick={() => deleteNote(n.id)}>
                            <DeleteIcon fontSize={"small"} />
                          </IconButton>
                        </Box>
                      </Box>
                    </CountCard>
                  );
                })}

              <Box width={"50%"} mt={2}>
                <AddButton label={"Add"} onClick={addNoteClick} />
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>

      <CustomModal open={addNoteModal} onClose={addNoteClick}>
        <NoteForm closeModal={addNoteClick} id={noteId} />
      </CustomModal>
    </TabPanel>
  );
};
