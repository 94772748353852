import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors, authSelectors } from "../../state";
import { useNavigate } from "react-router-dom";
import { CountCard, ToolBar } from "../../components";
import { DateTimePicker } from "@mui/x-date-pickers";
import { isValid } from "date-fns";
import { formatSeconds } from "../../lib";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { MENU_STATS_COLUMNS } from "../../components/tables/columns";
import { startOfToday, startOfYesterday } from "date-fns";
import { MainPages } from "../main";

export const DashboardPage = React.memo(function DashboardPage() {
  const admin = useSelector(authSelectors.isAdmin);
  const stats = useSelector(adminSelectors.stats);
  const menuStats = useSelector(adminSelectors.menuStats);
  const internal_app_options = useSelector(adminSelectors.internal_app_options);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [startDate, setStartDate] = React.useState<Date>(startOfYesterday());
  const [endDate, setEndDate] = React.useState<Date>(startOfToday());
  const [showExtStats, setShowExtStats] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = useState(10);
  const [location, setLocation] = useState<string>("");

  const cards = {
    total_calls_sub: {
      statsKey: "total_calls_sub",
      label_line1: "Total Calls",
      label_line2: "subscribers",
      getValue: () => stats["total_calls_sub"],
    },
    total_calls_non_sub: {
      statsKey: "total_calls_non_sub",
      label_line1: "Total Calls",
      label_line2: "non-subscribers",
      getValue: () => stats["total_calls_non_sub"],
    },
    unique_caller_id_sub: {
      statsKey: "unique_caller_id_sub",
      label_line1: "Unique Calls",
      label_line2: "subscribers",
      getValue: () => stats["unique_caller_id_sub"],
    },
    unique_child_id_sub: {
      statsKey: "unique_child_id_sub",
      label_line1: "Unique Children",
      label_line2: "subscribers",
      getValue: () => stats["unique_child_id_sub"],
    },
    unique_customer_id_sub: {
      statsKey: "unique_customer_id_sub",
      label_line1: "Unique Customers",
      label_line2: "subscribers",
      getValue: () => stats["unique_customer_id_sub"],
    },
    unique_caller_id_non_sub: {
      statsKey: "unique_caller_id_non_sub",
      label_line1: "Unique Calls",
      label_line2: "non-subscribers",
      getValue: () => stats["unique_caller_id_non_sub"],
    },
    avg_duration_sub: {
      statsKey: "avg_duration_sub",
      label_line1: "Avg call duration (min)",
      label_line2: "subscribers",
      getValue: () =>
        stats["avg_duration_sub"] !== "?"
          ? formatSeconds(stats["avg_duration_sub"])
          : "?",
    },
    avg_duration_non_sub: {
      statsKey: "avg_duration_non_sub",
      label_line1: "Avg call duration (min)",
      label_line2: "non-subscribers",
      getValue: () =>
        stats["avg_duration_non_sub"] !== "?"
          ? formatSeconds(stats["avg_duration_non_sub"])
          : "?",
    },
  };

  useEffect(() => {
    if (!admin) {
      nav(MainPages.loopDash.path);
    }
  }, [admin]);

  useEffect(() => {
    dispatch(adminActions.getStats(startDate, endDate));
  }, [startDate, endDate]);

  useEffect(() => {
    dispatch(adminActions.getMenus());
  }, [dispatch]);

  useEffect(() => {
    if (showExtStats) {
      dispatch(adminActions.getExtensionStats(startDate, endDate, location));
    }
  }, [startDate, endDate, showExtStats, location]);

  return (
    <Box height={"100vh"}>
      <Typography variant="h3">Dashboard</Typography>
      <Grid container spacing={3} alignItems={"center"}>
        <Grid item md={3} xs={12}>
          <DateTimePicker
            label="Start Time"
            value={startDate}
            onChange={(newValue) => {
              if (newValue && isValid(newValue)) {
                setStartDate(newValue);
              }
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <DateTimePicker
            label="End Time"
            value={endDate}
            onChange={(newValue) => {
              if (newValue && isValid(newValue)) {
                setEndDate(newValue);
              }
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Autocomplete
            id="selectLocation"
            //className={classes.filterDropdown}
            onChange={(e, newValue) => {
              setLocation(newValue?.id || "");
            }}
            options={internal_app_options}
            getOptionLabel={(option: any) => option?.id || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Select Location"
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControlLabel
            control={
              <Switch onChange={(_e, checked) => setShowExtStats(checked)} />
            }
            label="Extension stats"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {Object.keys(cards).map((i, idx) => (
          <Grid key={idx} item md={3} xs={12}>
            <CountCard sx={{ height: 140 }}>
              <Typography variant="h4">{cards[i].getValue()}</Typography>
              <Typography variant="h5">{cards[i].label_line1}</Typography>
              {cards[i].label_line2 && (
                <Typography variant="body1">{cards[i].label_line2}</Typography>
              )}
            </CountCard>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 8 }}>
        <Typography variant="h3">Extension Stats</Typography>
        {/* <Typography>
          Total hits per extension (the option for unique would be nice)
        </Typography>
        <Typography>Avg call duration per extension</Typography> */}
        <DataGridPro
          sx={{ align: "left" }}
          autoHeight
          rows={menuStats}
          columns={MENU_STATS_COLUMNS}
          pagination
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          components={{
            Toolbar: ToolBar,
          }}
          componentsProps={{
            toolbar: {
              showQuickSearch: true,
            },
          }}
        />
      </Box>
    </Box>
  );
});
