import UserPage from "./UserPage";
// import { DashboardPage } from "./DashboardPage";
import { UsersPage } from "./UsersPage";
import ProfilePage from "./ProfilePage";
import {
  DashboardIcon,
  GameIcon,
  GroupsIcon,
  LiveIcon,
  MenuIcon,
  // MicIcon,
  PeopleIcon,
  PointOfSaleIcon,
  RecordVoiceOverIcon,
  SummarizeIcon,
  TheaterComedyIcon,
  ToysIcon,
  VoicemailIcon,
  MailBox,
  LocalActivityIcon,
  SubscriptionsIcon,
} from "../../components";
import { CustomersPage } from "./CustomersPage";
import { ChildrenPage } from "./ChildrenPage";
// import { AppsPage } from "./AppsPage";
import { LivesShowsPage } from "./LivesShowsPage";
import { MenusPage } from "./MenusPage";
import { MenusCreateOrEdit } from "./MenusCreateOrEdit";
import { RecordingsPage } from "./RecordingsPage";
import { RecordingsCreatePage } from "./RecordingsCreatePage";
import { ChildPage } from "./ChildPage";
import { CustomerPage } from "./CustomerPage";
import { AppTypesCreateOrEdit } from "./AppTypesCreateOrEdit";
import { AppTypesPage } from "./AppTypesPage";
import { VoicemailsPage } from "./VoicemailsPage";
import { SerialsPage } from "./SerialsPage";
import { SerialsCreateOrEdit } from "./SerialsCreateOrEdit";
import { LiveShowCreateOrEdit } from "./LiveShowCreateOrEdit";
import { GamesPage } from "./GamesPage";
import { GamesRulesPage } from "./GamesRulesPage";
import { DashboardPage } from "./DashboardPage";
import { GamesCreateOrEdit } from "./GamesCreateOrEdit";
import { GameRulesCreateOrEdit } from "./GameRulesCreateOrEdit";
import { CustomerCreateOrEdit } from "./CustomerCreateOrEdit";
import { AliasesPage } from "./AliasesPage";
import { PointsPage } from "./PointsPage";
import { ReportsPage } from "./ReportsPage";
import ReportsViewPage from "./ReportsViewPage";
import { VoicemailBoxesPage } from "./VoicemailBoxesPage";
import { GamesVersionsPage } from "./GamesVersionsPage";
import { SeriesPage } from "./SeriesPage";
import { PlayCircle } from "@mui/icons-material";
import { SeriesCreateOrEdit } from "./SeriesCreateOrEdit";
import { RafflesPage } from "./RafflesPage";
import { SubscriptionsPlansPage } from "./SubscriptionsPlansPage";

export const AdminPages = {
  // apps: {
  //   anon: false,
  //   path: "/admin/apps",
  //   title: "Apps",
  //   view: AppsPage,
  //   icon: MicIcon,
  //   allowedRoles: ["super_admin"],
  //   menuItemOrder: 30,
  // },
  children: {
    anon: false,
    path: "/admin/children",
    title: "Children",
    view: ChildrenPage,
    icon: PeopleIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 20,
  },
  child: {
    anon: false,
    path: "/admin/children/:id/:tab",
    title: "Child",
    view: ChildPage,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  customers: {
    anon: false,
    path: "/admin/customers",
    title: "Customers",
    view: CustomersPage,
    icon: GroupsIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 10,
  },
  voicemail: {
    anon: false,
    path: "/admin/voicemails",
    title: "Voicemails",
    view: VoicemailsPage,
    icon: VoicemailIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 80,
  },
  voicemailBoxes: {
    anon: false,
    path: "/admin/voicemail-boxes",
    title: "Voicemail Boxes",
    view: VoicemailBoxesPage,
    icon: MailBox,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 80,
  },

  customer: {
    anon: false,
    path: "/admin/customers/:id/:tab",
    title: "Customer",
    view: CustomerPage,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  customerCreate: {
    anon: false,
    path: "/admin/customer/create",
    title: "Customer",
    view: CustomerCreateOrEdit,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  customerEdit: {
    anon: false,
    path: "/admin/customer/:userId/:customerId",
    title: "Customer",
    view: CustomerCreateOrEdit,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  dashboard: {
    anon: false,
    path: "/admin",
    title: "Dashboard",
    view: DashboardPage,
    icon: DashboardIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 1,
  },
  liveShow: {
    anon: false,
    path: "/admin/live",
    title: "Live shows",
    view: LivesShowsPage,
    icon: LiveIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 40,
  },
  liveShowCreate: {
    anon: false,
    path: "/admin/live/create",
    title: "LiveShows",
    view: LiveShowCreateOrEdit,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  LiveShowEdit: {
    anon: false,
    path: "/admin/live/:id",
    title: "LiveShows",
    view: LiveShowCreateOrEdit,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  menus: {
    anon: false,
    path: "/admin/menu",
    title: "Menus",
    view: MenusPage,
    icon: MenuIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 60,
  },
  menuCreate: {
    anon: false,
    path: "/admin/menu/create",
    title: "Menus",
    view: MenusCreateOrEdit,
    allowedRoles: ["super_admin"],
  },
  menusEdit: {
    anon: false,
    path: "/admin/menu/:id",
    title: "Menus",
    view: MenusCreateOrEdit,
    allowedRoles: ["super_admin"],
  },

  users: {
    anon: false,
    path: "/admin/users",
    title: "Users",
    view: UsersPage,
    icon: PeopleIcon,
    allowedRoles: ["super_admin", "admin"],
    menuItemOrder: 50,
  },
  usersEdit: {
    anon: false,
    path: "/admin/users/:id",
    title: "Edit User",
    view: UserPage,
    allowedRoles: ["super_admin", "admin"],
  },
  createUsers: {
    anon: false,
    path: "/admin/users/create",
    title: "Create User",
    allowedRoles: ["super_admin", "admin"],
    view: UserPage,
  },
  profile: {
    anon: false,
    path: "/admin/profile",
    title: "Edit Profile",
    view: ProfilePage,
  },
  recording: {
    anon: false,
    path: "/admin/recordings",
    title: "Recordings",
    view: RecordingsPage,
    icon: RecordVoiceOverIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 60,
  },
  recordingCreate: {
    anon: false,
    path: "/admin/recordings/create",
    title: "Create Recordings",
    view: RecordingsCreatePage,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  recordingEdit: {
    anon: false,
    path: "/admin/recordings/:id",
    title: "Edit Recordings",
    view: RecordingsCreatePage,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  appTypes: {
    anon: false,
    path: "/admin/app-types",
    title: "App Types",
    view: AppTypesPage,
    icon: RecordVoiceOverIcon,
    allowedRoles: ["super_admin"],
    menuItemOrder: 70,
  },
  appTypesCreate: {
    anon: false,
    path: "/admin/app-types/create",
    title: "App Types Create",
    view: AppTypesCreateOrEdit,
    allowedRoles: ["super_admin"],
  },
  appTypesEdit: {
    anon: false,
    path: "/admin/app-types/:id",
    title: "App Types Create",
    view: AppTypesCreateOrEdit,
    allowedRoles: ["super_admin"],
  },
  serials: {
    anon: false,
    path: "/admin/serials",
    title: "Serials",
    view: SerialsPage,
    icon: MenuIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 60,
  },
  serialsCreate: {
    anon: false,
    path: "/admin/serial/create",
    title: "Serials",
    view: SerialsCreateOrEdit,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  serialsEdit: {
    anon: false,
    path: "/admin/serial/:id",
    title: "Serials",
    view: SerialsCreateOrEdit,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  games: {
    anon: false,
    path: "/admin/games",
    title: "Games",
    view: GamesPage,
    icon: GameIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 90,
  },
  gamesCreate: {
    anon: false,
    path: "/admin/games/create",
    title: "Games",
    view: GamesCreateOrEdit,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  gamesEdit: {
    anon: false,
    path: "/admin/games/:id",
    title: "Games",
    view: GamesCreateOrEdit,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  game_rules: {
    anon: false,
    path: "/admin/game-rules",
    title: "Game Rules",
    view: GamesRulesPage,
    icon: ToysIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 100,
    subMenuOf: "games",
  },
  game_versions: {
    anon: false,
    path: "/admin/game-versions",
    title: "Game Versions",
    view: GamesVersionsPage,
    icon: ToysIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 100,
    subMenuOf: "games",
  },
  gameRulesCreate: {
    anon: false,
    path: "/admin/game-rules/create",
    title: "Game Rules",
    view: GameRulesCreateOrEdit,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  gameRulesEdit: {
    anon: false,
    path: "/admin/game-rules/:id",
    title: "Game Rules",
    view: GameRulesCreateOrEdit,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  alias: {
    anon: false,
    path: "/admin/alias",
    title: "Aliases",
    view: AliasesPage,
    icon: TheaterComedyIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 110,
  },
  childPoints: {
    anon: false,
    path: "/admin/points",
    title: "Child Points",
    view: PointsPage,
    icon: PointOfSaleIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 120,
  },
  reports: {
    anon: false,
    path: "/admin/reports",
    title: "Reports",
    view: ReportsPage,
    icon: SummarizeIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 130,
  },
  reportsView: {
    anon: false,
    path: "/admin/reports/:id",
    title: "Reports View",
    view: ReportsViewPage,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  series: {
    anon: false,
    path: "/admin/series",
    title: "Series",
    view: SeriesPage,
    icon: PlayCircle,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 150,
  },
  seriesCreate: {
    anon: false,
    path: "/admin/series/create",
    title: "Edit Series",
    view: SeriesCreateOrEdit,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  seriesEdit: {
    anon: false,
    path: "/admin/series/:id",
    title: "Edit Series",
    view: SeriesCreateOrEdit,
    allowedRoles: ["super_admin", "admin", "employee"],
  },
  raffles: {
    anon: false,
    path: "/admin/raffles",
    title: "Raffles",
    view: RafflesPage,
    icon: LocalActivityIcon,
    allowedRoles: ["super_admin", "admin", "employee"],
    menuItemOrder: 170,
  },
  subscriptionPlans: {
    anon: false,
    path: "/admin/subscription-plans",
    title: "Subscription Plans",
    view: SubscriptionsPlansPage,
    icon: SubscriptionsIcon,
    allowedRoles: ["super_admin", "admin"],
    menuItemOrder: 180,
  },
};

export default AdminPages;
