import { createSelector } from "reselect";
import { arrayToEntityList } from "../../lib";
import { AppState } from "../types";

const _state = (state: AppState) => state.admin;
const _menusObj = createSelector(_state, (state) =>
  arrayToEntityList(state.menus.rows),
);
const _children = createSelector(
  _state,
  (state) =>
    state.children.rows?.map((r) => ({
      ...r,
      name_audio_url: r.customer_child_recording?.full_url ?? null,
    })) || [],
);

const mapNameToLabel = (i) => ({ ...i, label: i.name });

export const adminSelectors = {
  users(state: AppState) {
    return state.admin.users.rows;
  },
  menus(state: AppState) {
    return state.admin.menus.rows;
  },
  games(state: AppState) {
    return state.admin.games;
  },
  gameRules(state: AppState) {
    return state.admin.gameRules;
  },
  liveShows(state: AppState) {
    return state.admin.liveShows.rows;
  },
  recordings(state: AppState) {
    return state.admin.recordings;
  },
  recordingsRows(state: AppState) {
    return state.admin.recordings.rows;
  },
  voicemails(state: AppState) {
    return state.admin.voicemails.rows;
  },
  voicemailRowCount(state: AppState) {
    return state.admin.voicemails.count;
  },
  voicemailBoxes(state: AppState) {
    return state.admin.voicemailBoxes.rows;
  },
  voicemailBox(state: AppState) {
    return state.admin.voicemailBox;
  },
  stats(state: AppState) {
    return state.admin.stats;
  },
  menuStats(state: AppState) {
    return state.admin.menuStats.map((r, idx) => ({ id: idx + 1, ...r }));
  },
  serials(state: AppState) {
    return state.admin.serials.rows.map((s) => ({ ...s, name: s.name ?? "" }));
  },
  serial(state: AppState) {
    return state.admin.serial;
  },
  series(state: AppState) {
    return state.admin.series;
  },
  serial_recordings(state: AppState) {
    return state.admin.serialRecordings.rows;
  },
  recording_types_options(state: AppState) {
    return state.admin.recording_types.rows.map((o) => ({
      id: o.id,
      label: o.name,
    }));
  },
  recordingTypes(state: AppState) {
    return state.admin.recording_types.rows;
  },
  menusObj(state: AppState) {
    return _menusObj(state).entities;
  },
  internal_app_options(state: AppState) {
    const internalApps = state.admin.menus.rows
      .map((o) => ({
        id: o.internal_name,
      }))
      .sort((a, b) => {
        const nameA = a.id.toLowerCase();
        const nameB = b.id.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    return [
      {
        id: "",
        label: "Not set",
      },
      ...internalApps,
    ];
  },
  appTypes(state: AppState) {
    //I added .slice to avoid Cannot assign to read only property '0' of object when sorting  error
    //but why was that necessary??
    const app_types = state.admin.app_types.rows.slice().sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    return {
      external: app_types
        .filter((at) => at.type === "external")
        .map(mapNameToLabel),
      internal: app_types
        .filter((at) => at.type === "internal")
        .map(mapNameToLabel),
    };
  },
  app_types(state: AppState) {
    return state.admin.app_types.rows;
  },
  externalAppTypes(state: AppState) {
    return _menusObj(state).entities;
  },
  customers(state: AppState) {
    return state.admin.customers.rows.map((r) => {
      return {
        ...r,
        name_audio_url: r?.customer_recording?.full_url ?? null,
        plans: r.subscriptions?.map((s) => s.subscription_plan?.plan_type),
        has_recording: !!r.customer_recording,
      };
    });
  },
  customersRowCount(state: AppState) {
    return state.admin.customers.count;
  },
  customer(state: AppState) {
    return state.admin.customer;
  },
  customerVoicemails(state: AppState) {
    return state.admin.customerVoicemails;
  },
  customerSubscriptions(state: AppState) {
    return (
      state.admin.customer.subscriptions?.map((s) => ({
        ...s,
        name: s?.subscription_plan?.name ?? "",
        plan_type: s?.subscription_plan?.plan_type,
        CB_customer_id: s?.subscription_meta?.customer_id,
        shipping_name: s?.shipping_first_name + " " + s?.shipping_last_name,
        phone: state.admin.customer.customer_phones.find(
          (cp) => cp.subscription_id === s.id,
        ),
      })) ?? []
    );
  },
  customerPhones(state: AppState) {
    return state.admin.customerPhone;
  },
  customerNotes(state: AppState) {
    return state.admin.customerNotes.rows;
  },
  customersStats(state: AppState) {
    return {
      activeCustomers: state.admin.stats.activeCustomers,
      activeChildren: state.admin.stats.activeChildren,
    };
  },
  children: _children,
  childrenRowCount(state: AppState) {
    return state.admin.children.count;
  },
  childInfo(state: AppState) {
    return state.admin.childInfo;
  },
  aliases(state: AppState) {
    return state.admin.aliases;
  },
  points(state: AppState) {
    return state.admin.points;
  },
  alias(state: AppState) {
    return state.admin.alias;
  },
  reports(state: AppState) {
    return state.admin.reports;
  },
  gameVersions(state: AppState) {
    return state.admin.gameVersions;
  },
  raffles(state: AppState) {
    return state.admin.raffles;
  },
  raffle(state: AppState) {
    return state.admin.raffle;
  },
  subscriptionPlans(state: AppState) {
    return state.admin.subscriptionPlans;
  },
};
